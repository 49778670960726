<template>
    <div>
        <VueMultiselect v-model="selected" :options="dragons" :searchable="true"
            :allow-empty="true" @select="updateSelected" label="name" :placeholder="placeholder"
            track-by="name" :disabled="isDisabled">
        </VueMultiselect>
    </div>
</template>

<script>
    import VueMultiselect from 'vue-multiselect'
    export default {
        components: {
            VueMultiselect
        },
        props: {
            dragons: Array,
            isDisabled: Boolean,
            placeholder: String
        },
        data() {
            return {
                selected: null,
            }
        },
        methods: {
            updateSelected(dragon) {
                // console.log(dragon)
                this.selected = dragon

                this.$emit('selected', dragon)
            }
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>