<template>
    <div>
        <section class="page-banner" style="background-image:url(/images/background/bg-banner-1.jpg);">
            <div class="top-pattern-layer"></div>

            <div class="banner-inner">
                <div class="auto-container">
                    <div class="inner-container clearfix">
                        <h1>Dragon Comparison</h1>
                    </div>
                </div>
            </div>
        </section>
        <div class="games-section games-page-section">
            <div class="auto-container">
                <div class="game-details">
                    <div class="inner">
                        <div class="lower-content">
                            <div class="title-box clearfix">                                
                                <div class="row">
                                    <div class="col col-md-12 col-lg-8 col-xs-12">
                                        <div class="title">
                                            <h2>Dragon Comparison</h2>
                                        </div>
                                    </div>
                                    <div class="col col-md-12 col-lg-4 col-xs-12">
                                        <SelectDragons :dragons="dragons" @selected="selectedDragon" :is-disabled="comparison.length === 3" placeholder="Select a dragon to compare"/>
                                    </div>
                                </div>
                            </div>
                            <div class="game-features">
                                <div class="row clearfix">
                                    <div class="feature-block col-lg-3 col-md-6 col-sm-12">
                                        <div class="inner-box text-center">
                                            <div style="text-align: right; font-weight: bold;">
                                                <a href="javascript:void(0)">
                                                    &nbsp;
                                                </a>
                                            </div>
                                            <div class="comparison-row" style="height: 146px;">

                                            </div>
                                            <div class="comparison-row">
                                                <h5>Name</h5>
                                            </div>
                                            <div class="comparison-row" style="height: 116px;">
                                                <h5>Elements</h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>Category</h5>
                                            </div>
                                            <div class="comparison-row" style="height: 56px;">
                                                <h5>Rarity</h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>Global Rank</h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>Rarity Rank</h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>Category Rank</h5>
                                            </div>
                                            <div class="comparison-row" style="height: 51px;">
                                                <h5>Basic skills</h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>Breedable</h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>Available in Shop</h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>Price Gems</h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>Buy Gold</h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>Sell Gold</h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>Hatching Time</h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>Summon Time</h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="feature-block col-lg-3 col-md-6 col-sm-12" v-for="dragon in comparison"
                                        :key="dragon.id">
                                        <div class="inner-box text-center">
                                            <div style="text-align: right;" @click="removeDragon(dragon)">
                                                <a href="javascript:void(0)">
                                                    Remove
                                                </a>
                                            </div>
                                            <div class="icon-box">
                                                <router-link :to="`/dragons/${dragon.urlName.toLowerCase()}`"
                                                    class="dragon-avatar">
                                                    <img :src="getAvatar(dragon, i)" v-for="i in 2" :key="i"
                                                        :alt="dragon.name" :title="dragon.name" @error="getErrorImg">
                                                </router-link>
                                            </div>
                                            <h4>
                                                <div class="comparison-row">
                                                    <router-link :to="`/dragons/${dragon.urlName.toLowerCase()}`">
                                                        {{ dragon.name }}
                                                    </router-link>
                                                </div>
                                            </h4>
                                            <div class="comparison-row">
                                                <span v-for="ele in dragon.dragonAttribute" :key="ele">
                                                    <img :src="`/images/dragons/elements/${ele.replace('/Content/Images/DragonType/', '')}`"
                                                        alt="Dragon Element" width="40">
                                                </span>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>{{ dragon.category }}</h5>
                                            </div>
                                            <div class="comparison-row">
                                                <img :src="`/images/dragons/type/${dragon.rarity}.png`"
                                                    alt="Dragon Type" width="50">
                                            </div>
                                            <div class="comparison-row">
                                                <h5>{{ numberFormat(dragon.rank.globalRank) }}</h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>{{ numberFormat(dragon.rank.rarityRank) }}</h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>{{ numberFormat(dragon.rank.categoryRank) }}</h5>
                                            </div>
                                            <div class="comparison-row">
                                                <span v-for="skill in dragon.attackSkills" :key="skill.id">
                                                    <img width="40"
                                                        :src="`/images/dragons/skills/${skill.element}-big.png`">&nbsp;&nbsp;
                                                </span>

                                            </div>
                                            <div class="comparison-row">
                                                <h5>{{ dragon.breedable ? 'Yes' : 'No' }}</h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>{{ dragon.inStore ? 'Yes' : 'No' }}</h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>{{ numberFormat(dragon.priceGem || 0) }} gem(s)</h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>{{ numberFormat(dragon.priceGold || 0) }} </h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>{{ numberFormat(dragon.priceSell || 0) }} </h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>{{ convertSecondsToTimeString(dragon.hatchingTime) || 0 }} </h5>
                                            </div>
                                            <div class="comparison-row">
                                                <h5>{{ convertSecondsToTimeString(dragon.breedingTime) || 0 }} </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SelectDragons from './Shared/SelectDragons.vue'

    export default {
        name: 'Comparison',
        components: {
            SelectDragons
        },
        props: {
            dragons: Array,
            elements: Array,
            skills: Object,
        },
        data() {
            return {
                comparison: []
            }
        },
        mounted() {
            this.loadDragons()
        },
        methods: {
            loadDragons() {
                const start = Math.floor(Math.random() * 1597)
                for (let i = start; i < (start + 2); i++) {
                    if (!this.dragons[i]) {
                        return
                    }
                    this.comparison.push(this.dragons[i])
                }
            },
            getAvatar(dragon, pos) {
                let name = dragon.image.replace('dragons/', '')
                return `/images/dragons/avatar/${name}_${pos - 1}.png`
            },
            numberFormat(value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            convertSecondsToTimeString(seconds) {
                var days = Math.floor(seconds / 86400);
                seconds = seconds % 86400;
                var hours = Math.floor(seconds / 3600);
                seconds = seconds % 3600;
                var minutes = Math.floor(seconds / 60);
                seconds = Math.round(seconds % 60);

                var durationPieces = [
                    days + 'd',
                    hours + 'h',
                    minutes + 'm',
                    seconds + 's',
                ].filter(piece => {
                    return piece[0] > 0;
                });

                return durationPieces.join(' ');
            },
            removeDragon(dragon) {
                const index = this.comparison.indexOf(dragon)
                this.comparison.splice(index, 1)
            },
            selectedDragon(dragon) {
                if (this.comparison.length < 3) {
                    this.comparison.push(dragon)
                }
            }
        }
    }
</script>